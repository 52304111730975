import { KalkulInc } from '@kalkul/components/types/Kalkul'
import { signIn, useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { ReactElement, useEffect, useState } from 'react'
import { sentryUserSet } from '../../services/sentry'
import AppLoader from '../app-loader'

interface PageProtectorProps {
    roles: KalkulInc.People.Role[]
    redirectTo: string
}

const PageProtector: React.FC<PageProtectorProps> = ({ children, roles, redirectTo }) => {
    const { data: session, status: sessionStatus } = useSession()
    const [isUserSignedIn, setIsUserSignedIn] = useState(!!session?.user.id)
    const { push } = useRouter()

    const isUserRoleAllowed = () => {
        return session && (roles.length === 0 || roles.includes(session.user.role))
    }

    useEffect(() => {
        if (isUserSignedIn !== !!session?.user.id) {
            setIsUserSignedIn(!!session?.user.id)
        }
    }, [session])

    useEffect(() => {
        if (sessionStatus === 'loading') {
            return
        }

        if (!isUserSignedIn) {
            signIn()
        }
    }, [isUserSignedIn, sessionStatus])

    useEffect(() => {
        if (!session || roles.length === 0) {
            return
        }

        if (!roles.includes(session.user.role) && redirectTo) {
            push(redirectTo)
        }
    }, [isUserSignedIn, roles])

    if (isUserRoleAllowed() && isUserSignedIn && children) {
        sentryUserSet()

        return children as ReactElement
    }

    return (
        <AppLoader />
    )
}

export default PageProtector
