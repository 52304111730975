import '@kalkul/components/styles/_reset.scss'
import '@kalkul/components/styles/_globals.scss'
import '../styles/_globals.scss'
import { appWithTranslation } from 'next-i18next'
import { SessionProvider } from 'next-auth/react'
import { ProtectedAppProps } from '../types/protected'
import Toaster from '@kalkul/components/components/toaster'
import PageProtector from '../components/page-protector'
import SessionContainer from '../components/session-container'

function KalkulDotCom({ Component, pageProps: { session, ...pageProps } }: ProtectedAppProps) {
    return (
        <SessionProvider session={session}>
            {
                Component.protected
                    ? (
                        <PageProtector {...Component.protected} >
                            <Component {...pageProps} />
                        </PageProtector>
                    )
                    : <Component {...pageProps} />
            }
            <Toaster />
            <SessionContainer />
        </SessionProvider>
    )
}

export default appWithTranslation(KalkulDotCom)
