import Loader from '@kalkul/components/components/loader'
import styles from './styles.module.scss'

const AppLoader = () => (
    <div className={styles.wrapper}>
        <Loader size="m" />
    </div>
)

export default AppLoader
