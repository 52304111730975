import { getSession } from 'next-auth/react'
import * as Sentry from '@sentry/nextjs'
import { isBrowserEnvironment } from '@kalkul/components/services/helpers'

export const sentryUserSet = async () => {
    if (!isBrowserEnvironment()) {
        return
    }

    const session = await getSession()

    if (session && window.localStorage.getItem('KALKUL_IS_SENTRY_SET') !== 'true') {
        Sentry.setUser({
            id: session.user.id,
            email: session.user.email,
            username: session.user.name,
        })

        window.localStorage.setItem('KALKUL_IS_SENTRY_SET', 'true')
    }
}

export const sentryUserClear = () => {
    if (!isBrowserEnvironment()) {
        return
    }

    Sentry.configureScope(scope => scope.setUser(null))

    window.localStorage.removeItem('KALKUL_IS_SENTRY_SET')
}
