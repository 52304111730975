import { DragEvent } from 'react'
import { KalkulInc } from '../types/Kalkul'
import type { ParsedUrlQuery } from 'querystring'
import Hls from 'hls.js'

export const getKeys = Object.keys as <T extends Record<string, unknown>>(obj: T) => Array<keyof T>
export const isProduction = process.env.NODE_ENV === 'production'
export const isBrowserEnvironment = () => typeof window !== 'undefined' && typeof document !== 'undefined'
export const isDebug = isBrowserEnvironment() ? window.localStorage.getItem('KALKUL_IS_DEBUG') === 'true' : false
export const isPrimitiveType = (test: any) => test !== Object(test)
export const isObject = (obj: any) =>  obj !== null && typeof obj === 'object' && !Array.isArray(obj)

export const screenDimensions = () => {
    if (isBrowserEnvironment()) {
        return {
            width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
            height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
        }
    }

    return {
        width: 1600,
        height: 900,
    }
}

export function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

// Localised string

export interface LocalizedLanguage {
    current?: string
    default?: string
}

interface GetLocalisedStringProps {
    resources: KalkulInc.Kalkul.LocalizedString[]
    language: LocalizedLanguage
}

export const getLocalisedString = ({ resources = [], language }: GetLocalisedStringProps) => {
    let resource = resources.find(item => item.language === language.current)

    if (!resource) {
        resource = resources.find(item => item.language === language.default)
    }

    return resource
}

// Combine path for asset

interface GetLocalisedAssetProps {
    assets: KalkulInc.Kalkul.LocalizedAsset[]
    language: LocalizedLanguage
}

interface CombineLocalisedItemAssetPathProps extends GetLocalisedAssetProps {
    type: keyof Pick<KalkulInc.Catalogue.Item, 'disc' | 'web_info' | 'module' | 'appclip'>
    item: KalkulInc.Catalogue.Item,
    hostname?: string
    extensionReplace?: string
}

interface CombineLocalisedNodeAssetPathProps extends GetLocalisedAssetProps {
    kalkul: KalkulInc.Catalogue.Item,
    hostname: string
}

interface CombineAvatarPathProps {
    filename: string
    hostname: string
}

export const getLocalisedAsset = ({ assets, language }: GetLocalisedAssetProps) => {
    if (!assets || assets.length === 0) {
        return null;
    }

    if (assets.length === 1) {
        return assets[0];
    }

    let resource = assets.find(item => item.language === language.current);

    if (!resource) {
        resource = assets.find(item => item.language === language.default);
    }

    return resource;
}

export const combineAvatarPath = ({ filename, hostname }: CombineAvatarPathProps) => {
    return `${hostname}/users/avatars/${filename}`
}

export const combineLocalisedNodeAssetPath = ({ kalkul, assets, language, hostname }: CombineLocalisedNodeAssetPathProps) => {
    const assetLocalised = getLocalisedAsset({ assets, language })

    if (!assetLocalised) {
        return ''
    }

    const pathKalkul = kalkul.path
    const pathLanguage = assetLocalised?.language
    let pathFile = assetLocalised?.src

    if (!pathKalkul || !pathLanguage || !pathFile) {
        return ''
    }

    return `${hostname}/${pathKalkul}/nodes/${pathLanguage}/${pathFile}`
}

export const combineLocalisedItemAssetPath = ({ item, type, assets, language, hostname, extensionReplace }: CombineLocalisedItemAssetPathProps) => {
    const assetLocalised = getLocalisedAsset({ assets, language })

    if (!assetLocalised) {
        return ''
    }

    let pathFile = assetLocalised?.src

    if (startsWithHttp(pathFile)) {
        // If the src contains a full url, it's considered a public link, no test for private assets hostname is needed
        return pathFile
    }

    const pathEntity = item[type]?.path
    const pathKalkul = item.path
    const pathLanguage = assetLocalised?.language

    if (!pathKalkul || !pathEntity || !pathLanguage || !pathFile) {
        return ''
    }

    if (extensionReplace) {
        pathFile = pathFile.replace(/\.[^/.]+$/, `.${extensionReplace}`)
    }

    if (hostname) {
        return `${hostname}/${pathKalkul}/${pathEntity}/${pathLanguage}/${pathFile}`
    } else {
        return `${pathKalkul}/${pathEntity}/${pathLanguage}/${pathFile}`
    }
}

export const getNestedProp = (source: Record<string, any>, path: string[]): any | undefined => {
    return path.reduce((obj, key) => obj && obj[key], source)
}

export const setNestedProp = (obj: Record<string, any> = {}, [key, ...rest]: string[], data: any): Record<string, any> => ({
    ...obj,
    [key]: rest.length > 0
        ? setNestedProp(obj[key], rest, data)
        : data,
})

export const removeNestedProps = (obj: Record<string, any> = {}, keysArray: string[][]): Record<string, any> => {
    let objNew = obj

    keysArray.forEach(keys => {
        objNew = removeNestedProp(objNew, keys)
    })

    return objNew
}

export const removeNestedProp = (obj: Record<string, any> = {}, [key, ...rest]: string[]): Record<string, any> => {
    if (rest.length > 0) {
        return {
            ...obj,
            [key]: removeNestedProp(obj[key], rest),
        }
    } else {
        const { [key]: _omit, ...leave } = obj

        return leave
    }
}

export const removeEmptyProps = <T>(obj: T): T => {
    //https://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript#comment122465139_38340730
    const clean = Object.fromEntries(
        Object.entries(obj)
            .map(([k, v]) => [k, v === Object(v) ? removeEmptyProps(v) : v])
            .filter(([_, v]) => v != null && (v !== Object(v) || Object.keys(v).length))
    )

    return Array.isArray(obj) ? Object.values(clean) : clean
}

export const removeEmptyLocalisedResources = (array: (KalkulInc.Kalkul.LocalizedAsset | KalkulInc.Kalkul.LocalizedString)[]): (KalkulInc.Kalkul.LocalizedAsset | KalkulInc.Kalkul.LocalizedString)[] => {
    return array.map(item => {
        if (
            ('src' in item && item.src) ||
            ('string' in item && item.string)
        ) {
            return item
        }

        return null
    }).filter(item => item !== null) as (KalkulInc.Kalkul.LocalizedAsset | KalkulInc.Kalkul.LocalizedString)[]
}

export type ValuesOf<T> = T[keyof T]

export type PickKey<T, K extends keyof T> = Extract<keyof T, K>

const renderDateDefaultOptions: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'long',
}

export const renderDate = (props: {
    date: string,
    locale?: KalkulInc.UI.Language | undefined,
    options?: Intl.DateTimeFormatOptions
}) => {
    try {
        const dateLocalised = new Date(props.date)

        return dateLocalised.toLocaleDateString(
            props.locale || 'en',
            {
                ...renderDateDefaultOptions,
                ...props.options,
            }
        )
    } catch (e) {}

    return props.date
}

const renderDateRelativeDefaultOptions: Intl.RelativeTimeFormatOptions = {
    style: 'long',
}

const treatAsUTC = (date: string) => {
    const result = new Date(date)
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset())

    return result
}

const daysBetween = (datePast: string, dateFuture: string) => {
    const millisecondsPerDay = 24 * 60 * 60 * 1000

    return (Number(treatAsUTC(dateFuture)) - Number(treatAsUTC(datePast))) / millisecondsPerDay
}

export const renderDateRelative = (props: {
    datePast: string,
    dateFuture: string,
    locale?: KalkulInc.UI.Language | undefined,
    options?: Intl.RelativeTimeFormatOptions,
    unit?: Intl.RelativeTimeFormatUnit
}) => {
    try {
        const daysCount = daysBetween(props.dateFuture, props.datePast)
        const relativeFormatter = new Intl.RelativeTimeFormat(props.locale, {
            ...renderDateRelativeDefaultOptions,
            ...props.options,
        });

        return relativeFormatter.format(Math.floor(daysCount), props.unit || 'day')
    } catch (e) {}

    return props.datePast
}

export const rgbColourPercentsToHexColour = (props: KalkulInc.Kalkul.Color) => {
    if (!props) {
        return undefined
    }

    const { red, green, blue, alpha } = props

    const decimalValues = {
        r: Math.round(red * 255),
        g: Math.round(green * 255),
        b: Math.round(blue * 255),
        a: Math.round(alpha * 255),
    }

    const hexValues = {
        r: `${decimalValues.r < 11 ? '0' : ''}${decimalValues.r.toString(16)}`,
        g: `${decimalValues.g < 11 ? '0' : ''}${decimalValues.g.toString(16)}`,
        b: `${decimalValues.b < 11 ? '0' : ''}${decimalValues.b.toString(16)}`,
        a: `${decimalValues.a < 10 ? '0' : ''}${decimalValues.a.toString(16)}`,
    }

    return `${hexValues.r}${hexValues.g}${hexValues.b}${alpha !== 1 ? hexValues.a : ''}`
}

export const hexColourToRgbColourPercents = (hexString: string): KalkulInc.Kalkul.Color => {
    if (hexString.length < 6) {
        return { red: 0, green: 0, blue: 0, alpha: 1 }
    }

    const hex = hexString.replace(/#/g, '')

    return {
        red: parseFloat((parseInt(hex.substring(0, 2), 16) / 255).toFixed(3)),
        green: parseFloat((parseInt(hex.substring(2, 4), 16) / 255).toFixed(3)),
        blue: parseFloat((parseInt(hex.substring(4, 6), 16) / 255).toFixed(3)),
        // uncomment to use alpha
        alpha: parseFloat((parseInt(/* hex.substring(6) || */ 'ff', 16) / 255).toFixed(3)),
    }
}

export const hexColourIsValid = (hexString: string) => {
    return /^#?(([0-9A-F]{3}){1,2}|([0-9A-F]{4}){1,2})$/i.test(hexString)
}

export const hexColourFix = (hexString: string) => {
    let hex = hexString.replace(/#/g, '').replace(/[^0-9A-F]/ig, '0')

    while (hex.length < 3 || hex.length === 5 || hex.length === 7) {
        hex += '0'
    }

    if (hex.length > 8) {
        hex = hex.slice(0, 8)
    }

    return hex
}

export const hexColourExpand = (hexString: string) => {
    return hexString.replace(/#/g, '').replace(/./g, '$&$&')
}

export const scrollIfNeeded = (element: HTMLElement, container: HTMLElement) => {
    if (
        container.scrollTop + container.offsetHeight < element.offsetTop ||
        element.offsetTop + element.offsetHeight < container.scrollTop
    ) {
        container.scrollTop = element.offsetTop
    }
}

export const inputDroppedItemsAllowOnlyAccepted = (event: DragEvent<HTMLInputElement>) => {
    const file = event.dataTransfer?.files[0]

    if (!file) {
        return
    }

    const input = event.target as HTMLInputElement

    if (
        input.type !== 'file' ||
        !input.accept ||
        !file.type.includes('/')
    ) {
        return
    }

    const [fileType, fileExtension] = file.type.split('/')
    const fileTypesAccepted: string[] = []
    const fileExtensionsAccepted: string[] = []

    input.accept.split(',').forEach(item => {
        if (item.includes('.')) {
            fileExtensionsAccepted.push(item.substring(item.lastIndexOf(".") + 1))
        } else if (item.includes('/')) {
            item.split('/').some((part, index) => {
                if (part && part !== '*') {
                    if (index === 0) {
                        fileTypesAccepted.push(part)
                    } else {
                        fileExtensionsAccepted.push(part)
                    }
                }

                return index === 2
            })
        }
    })

    if (!fileTypesAccepted.includes(fileType) && !fileExtensionsAccepted.includes(fileExtension)) {
        event.preventDefault()
    }
}

export const listenCookieChange = (callback: () => void) => {
    const lastCookie = document.cookie

    const  interval = setInterval(()=> {
        if (document.cookie !== lastCookie) {
            clearInterval(interval)

            callback()
        }
    }, 1000)
}

export const arraySplitToChunks = <T>(array: T[], chunkSize: number): T[][] => {
    const chunks: T[][] = []

    for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize))
    }

    return chunks
}

export const removeAllChildNodes = (parent: Node) => {
    while (parent.firstChild) {
        parent.removeChild(parent.firstChild)
    }
}

export const startsWithHttp = (string: string | undefined)  => {
    if (!string) {
        return false
    }

    const isLink = /^http(s?):\/\//i

    return isLink.test(string)
}

export const getRouterQueryString = (query: ParsedUrlQuery) => {
    if (Object.keys(query).length === 1) {
        return ''
    }

    let string = ''

    Object.keys(query).forEach(key => {
        if (key !== 'path') {
            string += `${string.length === 0 ? '?' : '&'}${key}=${query[key]}`
        }
    })

    return string
}

interface AppendHlsVideoProps {
    hlsInstance: Hls
    node: HTMLVideoElement
    src: string
}

export const appendHlsVideo  = ({ hlsInstance, node, src }: AppendHlsVideoProps) => {
    let isHlsMounted = false

    if (Hls.isSupported()) {
        hlsInstance.detachMedia()
        hlsInstance.loadSource(src)
        hlsInstance.attachMedia(node)

        isHlsMounted = true
    } else if (node.canPlayType('application/vnd.apple.mpegurl')) {
        node.src = src
    }

    return isHlsMounted
}
