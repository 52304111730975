import { Toaster } from 'react-hot-toast'
import styles from './styles.module.scss'
import COLORS from '../../styles/exports.colours.module.scss'

const ToasterWrapper = () => {
    return (
        <Toaster
            position="bottom-center"
            toastOptions={{
                className: styles.wrapper,
                iconTheme: {
                    primary: COLORS.white,
                    secondary: COLORS.orange,
                },
            }}
        />
    )
}

export default ToasterWrapper
