import classnames from 'classnames'
import { KalkulInc } from '../../types/Kalkul'
import styles from './styles.module.scss'

export interface LoaderProps {
    readonly size: KalkulInc.UI.Size | 'xs' | 'xl'
    readonly className?: string
}

const Loader: React.FC<LoaderProps> = ({ size, className }) => {
    let classNames = {
        [styles.wrapper]: true,
    }

    if (className) {
        classNames = {
            ...classNames,
            [className]: true,
        }
    }

    return (
        <div className={classnames(classNames)} data-size={size}>
            <div className={styles.spinner}></div>
            <div className={styles.body}>
                <div className={styles.inner}></div>
            </div>
            <div className={styles.reflections}>
            </div>
        </div>
    )
}

export default Loader
