import { getSession } from 'next-auth/react'
import { Session } from 'next-auth'
import { useEffect } from 'react'
import styles from './styles.module.scss'

export const sessionAccessor = {
    refresh: (): Promise<Session | null> => new Promise(resolve => {})
}

const SessionContainer: React.FC = () => {
    const triggerSession = async () => {
        const session = await getSession()

        return session
    }

    useEffect(() => {
        sessionAccessor.refresh = triggerSession
    }, [])

    return (
        <div className={styles.wrapper}></div>
    )
}

export default SessionContainer
